<template>
  <div class="success-modal">
    <i>
      <IconSuccess />
    </i>
    <slot />
  </div>
</template>

<script>
import IconSuccess from '@/components/ui/icons/icon-check.svg'

export default {
  components: {
    IconSuccess,
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.success-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;

  i {
    margin-bottom: 10px;
  }
}
</style>
