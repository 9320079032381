<template>
  <span class="load-spinner">
    <LoadSpinner class="spinner" />
  </span>
</template>

<script>
import LoadSpinner from '@/components/ui/icons/spinner.svg'

export default {
  components: {
    LoadSpinner,
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.load-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);

  @media all and (min-width: $screen-width-767) {
    top: 0;
    bottom: 0;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .spinner {
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  svg {
    height: 100%;
    width: 100%;
    animation: spin 700ms linear infinite;
  }
}
</style>
